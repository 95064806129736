/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from "jquery";
import autosize from "autosize";
// import ahoy from "ahoy.js";
global.$ = $;
global.jQuery = $;
import tether from "tether";
global.Tether = tether;

require("@rails/ujs").start();
global.Rails = Rails;
require("@rails/activestorage").start();
require("channels");

require("jquery");
require("jquery-ui");
require("../src/autocomplete-rails");
require("tether");
require("popper.js/dist/umd/popper");
require("bootstrap/dist/js/bootstrap");
require("bootstrap-notify/bootstrap-notify");
require("jquery-mask-plugin");
require("select2/dist/js/select2.full.min");
require("jquery-textcomplete");
require("jquery-match-height");
require("jquery-jscroll");
// require("recaptcha/lib/recaptcha");
require("../src/facebook");

require("../src/components/comment");
require("../src/components/discussion");
require("../src/components/follow");
require("../src/components/follows");
require("../src/components/hashtaggable");
require("../src/components/infinite_scroll");
require("../src/components/news_feed");
require("../src/components/preview-img");
require("../src/components/question");
require("../src/components/remote_buttons");
require("../src/components/report");
require("../src/components/search");
require("../src/components/sidebar");
require("../src/components/upvote");
require("../src/components/vote");
require("../src/components/verification");
require("../src/components/claims");

global.jQuery.railsAutocomplete.options.delay = 300;
global.jQuery.railsAutocomplete.options.autoFocus = true;
// ahoy.start();
let login = function () {
  window.location.href = "/users/login";
};

$(function () {
  $(document).on("click", "[data-url]", function (event) {
    const $data = $(this).attr("data");
    if (window.location.pathname === $(this).data("url")) {
      event.preventDefault();
    } else if (window.location.href.toString().includes(`officials/${$data}`) && $(this).data("url").toString().includes(`officials/${$data}`)) {
      event.preventDefault();
    } else {
      window.location.href = $(this).data("url");
    }
  });
  $(document).on("click", ".no-reload", function (event) {
    const $data = $(this).attr("data");
    if (window.location.href.toString().includes($data) && $(this).attr("href").toString().includes($data)) {
      event.preventDefault();
    } else {
      window.location.href = $(this).attr("href");
    }
  });
  $.each($('[data-autosize="true"]'), function (i, $autosizable) {
    autosize($autosizable);
  });
  $('[data-provide="jscroll"]').jscroll({
    nextSelector: "a.jscroll-next:last",
    padding: 10000,
    loadingHtml: '<span class="loading"></span>',
  });
  $(".phone-us").mask("(000) 000-0000"); //#region Select2 Lists

  $("select.custom-select").select2({
    width: "100%",
    minimumResultsForSearch: Infinity,
    containerCssClass: "wevote-select",
    dropdownCssClass: "wevote-dropdown",
  });
  $("select.sort-select").select2({
    width: "100%",
    minimumResultsForSearch: Infinity,
    containerCssClass: "sort-select",
    dropdownCssClass: "wevote-dropdown",
  });
  $("select.filter-select").select2({
    width: "100%",
    minimumResultsForSearch: Infinity,
    containerCssClass: "filter-select",
    dropdownCssClass: "wevote-dropdown",
  });
  $("select.issues-select").select2({
    width: "100%",
    multiple: true,
    tags: true,
    placeholder: "add an issue",
    theme: "bootstrap",
    createTag: function () {
      return undefined;
    },
  }); //#endregion
  // This prevents the comments and the positions on bill cards to be open at the same time

  $(".comments-collapse").on("show.bs.collapse", function () {
    $(this).siblings(".positions-collapse").collapse("hide");
  });
  $(".positions-collapse").on("show.bs.collapse", function () {
    $(this).siblings(".comments-collapse").collapse("hide");
  }); //#region Toggles

  $("#nameToggle").click(function () {
    $(this).toggleClass("toggled");
    $(this).siblings("label").toggleClass("selected");
    $("#hideFullName").val($(this).siblings(".selected").data("toggle"));
  });
  $("#votesToggle").click(function () {
    $(this).toggleClass("toggled");
    $(this).siblings("label").toggleClass("selected");
    $("#hideVotes").val(!$(this).hasClass("toggled"));
  });
  $("#postsToggle").click(function () {
    $(this).toggleClass("toggled");
    $(this).siblings("label").toggleClass("selected");
    $("#hidePosts").val(!$(this).hasClass("toggled"));
  });
  $("#typeToggle").click(function () {
    $(this).toggleClass("toggled");
    $(this).siblings("label").toggleClass("selected");
    $("#hidePosts").val(!$(this).hasClass("toggled"));
  });
  $("#post-type > li > a, #post-type > li > span").click(function (e) {
    e.preventDefault();
    $("#post-type > li").removeClass("selected");
    $(this).parent().toggleClass("selected");
  });
  var placeholderColor = "#D6E0E6";
  $(".state-select")
    .css("color", placeholderColor)
    .change(function () {
      if ($(this).val() === "") $(this).css("color", placeholderColor);
      else $(this).css("color", "white");
    });
  $(".account-form .code-row input").keyup(function (e) {
    var key = e.keyCode,
      prevId = $(this).data("previous"),
      nextId = $(this).data("next");
    if (key === 8 && $(this).val().length === 0) $(prevId).focus();
    else if (this.value.length === this.maxLength) $(nextId).focus();
  });
});
document.addEventListener('DOMContentLoaded', function() {
  var toasts = $('.toast');
  toasts.each(function() {
    var toast = $(this);
    toast.toast('show');
  });
});
